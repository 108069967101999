export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export const withHttps = (url) => {
    let safeUrl = url ? String(url) : ""; // fix error Cannot read properties of undefined (reading 'includes')
    if (safeUrl.includes('https://')) {
        return url;
    } else {
        return `https://${safeUrl}`
    }
}

export const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
}


export const getEduqatURL = (siteID) => {
    if (process.env.REACT_APP_API_BASE.includes('reduqate')) {
        return `${siteID}.reduqate.com`
    } else {
        return `${siteID}.eduqat.com`
    }

}